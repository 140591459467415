<template>
  <div class="container mt-1">
    <div class="py-3 text-center">
      <img
        class="d-block mx-auto mb-3 img-fluid rounded-circle"
        src="/logo.png"
        alt=""
        style="max-width: 80px; height: auto"
      />
      <h2>LOAD CALC</h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-3 mb-3">
        <div class="card">
          <div class="card-body">
            <label for="price" class="form-label"
              >Rate <b><span class="text-danger">*</span></b></label
            >
            <input
              v-model="price"
              type="number"
              class="form-control"
              id="price"
            />
            <div class="w-100 mt-2">
              <label for="var_noload" class="form-label"
                >Load # <small style="font-size: 10px">(OPTIONAL)</small></label
              >
              <input
                v-model="var_noload"
                type="text"
                class="form-control"
                id="var_noload"
                @change="info_broker"
              />
            </div>
            <div class="w-100 mt-2">
              <label for="eta" class="form-label"
                >ETA <small style="font-size: 10px">(OPTIONAL)</small></label
              >
              <select
                v-model="eta"
                id="eta"
                class="form-select"
                aria-label="eta"
                @change="info_broker"
              >
                <option
                  v-for="(item, index) in arrtimes"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="w-100 mt-2">
              <label for="note" class="form-label"
                >Driver <small style="font-size: 10px">(OPTIONAL)</small></label
              >
              <select
                v-if="var_drivers.length"
                v-model="note"
                id="note"
                class="form-select"
                aria-label="Default select example"
                @change="info_broker"
              >
                <option
                  v-for="item in var_drivers"
                  :key="item.id"
                  :value="`${item.driver.name} : ${item.driver.phone}   Truck: ${item.tagname}  Trailer: ${item.trailer.tagname}`"
                >
                  {{
                    item.driver.name +
                    " : " +
                    item.driver.phone +
                    "  Truck: " +
                    item.tagname +
                    "  Trailer: " +
                    item.trailer.tagname
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="price" class="col-sm-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <label for="var_miles_empty" class="form-label"
              >Last Drop Location
              <small style="font-size: 10px">(OPTIONAL)</small></label
            >
            <input
              v-model="var_miles_empty"
              type="text"
              class="form-control"
              id="var_miles_empty"
            />
          </div>
        </div>
      </div>
      <div v-if="price" class="col-sm-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <label for="var_pick_up_location" class="form-label"
              >Pick Up Location <b><span class="text-danger">*</span></b></label
            >
            <input
              v-model="var_pick_up_location"
              type="text"
              class="form-control"
              id="var_pick_up_location"
            />
            <div class="d-grid gap-2 mt-3">
              <button
                @click="add_pick_up"
                class="btn btn-primary btn-sm"
                type="button"
                :disabled="!var_pick_up_location"
              >
                ADD PICK UP
              </button>
            </div>
          </div>
        </div>
        <ul v-if="var_pick_up_list.length" class="list-group mt-3">
          <li
            v-for="(item, index) in var_pick_up_list"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            ({{ index + 1 }}) {{ item }}
            <span
              @click="delete_pick_up(index)"
              class="badge bg-danger rounded-pill cursor-pointer"
              >X</span
            >
          </li>
        </ul>
      </div>
      <div v-if="price && var_pick_up_list.length" class="col-sm-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <label for="var_receiver" class="form-label"
              >Receiver Location
              <b><span class="text-danger">*</span></b></label
            >
            <input
              v-model="var_receiver"
              type="text"
              class="form-control"
              id="var_receiver"
            />
            <div class="d-grid gap-2 mt-3">
              <button
                @click="add_receiver"
                class="btn btn-primary btn-sm"
                type="button"
                :disabled="!var_receiver"
              >
                ADD RECEIVER
              </button>
            </div>
          </div>
        </div>
        <ul v-if="var_receiver_list.length" class="list-group mt-3">
          <li
            v-for="(item, index) in var_receiver_list"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            ({{ index + 1 }}) {{ item }}
            <span
              @click="delete_receiver(index)"
              class="badge bg-danger rounded-pill cursor-pointer"
              >X</span
            >
          </li>
        </ul>
      </div>
      <div class="col-sm-12">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            class="btn btn-info me-md-2"
            type="button"
            :disabled="!price || var_all_list.length < 2"
            @click="calcform_var_form"
          >
            CALC LOAD
          </button>
          <button class="btn btn-dark" type="button" @click="reset_var_form">
            Reset
          </button>
        </div>
      </div>
      <div class="col-sm-12"><hr /></div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-3">
            <b>Drops</b>
            {{ `${var_pick_up_list.length}P  ${var_receiver_list.length}D` }}
          </div>
          <div class="col-sm-9"><p v-html="traveltext"></p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <div v-if="is_calc" class="w-100">
          <div class="card">
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-sm-2"><b>TMiles</b></div>
                <div class="col-sm-4">
                  {{
                    var_mi_empty
                      ? formatNumber(milles + var_mi_empty)
                      : formatNumber(milles)
                  }}
                </div>
                <div class="col-sm-2"><b>Rate</b></div>
                <div class="col-sm-4">{{ formatNumber(price) }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-2"><b>TRPM</b></div>
                <div class="col-sm-4">{{ total }}</div>
                <div class="col-sm-2"><b>LRPM</b></div>
                <div class="col-sm-4">{{ var_lrpm }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-2"><b>LMiles</b></div>
                <div class="col-sm-4">{{ formatNumber(milles) }}</div>
                <div class="col-sm-2"><b>EMiles</b></div>
                <div class="col-sm-4">{{ var_mi_empty }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-2"><b>Days</b></div>
                <div class="col-sm-4">---</div>
                <div class="col-sm-2"><b>D/R</b></div>
                <div class="col-sm-4">---</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <div v-if="is_calc" class="container py-5">
          <div class="row">
            <div class="col-md-12">
              <div id="content">
                <ul class="timeline-1 text-black">
                  <li
                    v-if="var_miles_empty"
                    class="event"
                    :data-date="var_miles_empty"
                  >
                    <h4 class="mb-3">Last Drop Location</h4>
                    <p v-if="3 > 4">{{ var_miles_empty }}</p>
                    <small
                      >Empty: {{ var_mi_empty }} | Time:
                      {{ var_timemi_empty }}</small
                    >
                  </li>
                  <template v-if="var_all_list.length">
                    <li
                      v-for="(item, index) in var_all_list"
                      :key="index"
                      class="event"
                      :data-date="item.element"
                    >
                      <h4 v-if="item.type == 'pick_up'" class="mb-3 pt-1">
                        {{
                          var_pick_up_list.length == 1
                            ? "Pick Up Location"
                            : `${item.iter} Pick Up Location`
                        }}
                      </h4>
                      <h4 v-if="item.type == 'receiver'" class="mb-3 pt-1">
                        {{ `${item.iter}D Receiver` }}
                      </h4>
                      <p v-if="3 > 4">{{ item.element }}</p>
                      <small v-if="index + 1 < var_all_list.length"
                        ><span v-if="item.type == 'pick_up'"
                          >Loaded M:
                          {{
                            var_mi_list.length > index
                              ? formatNumber(var_mi_list[index].mi)
                              : ""
                          }}
                          | </span
                        ><span v-if="item.type == 'receiver'"
                          >Miles:
                          {{
                            var_mi_list.length > index
                              ? formatNumber(var_mi_list[index].mi)
                              : ""
                          }}
                          | </span
                        >Time:
                        {{
                          var_mi_list.length > index
                            ? var_mi_list[index].time
                            : ""
                        }}</small
                      >
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="price && 3 > 4" class="col-sm-6 mb-3">
        <label for="trip" class="form-label"
          >TRIP <b><span class="text-danger">*</span></b></label
        >
        <input
          v-model="trip"
          type="text"
          class="form-control"
          id="trip"
          placeholder="CITY 1, FL;CITY 2, TX;CITY 3, TX"
          @change="print_tt"
        />
      </div>
      <div v-if="price && 3 > 4" class="col-sm-1 mb-3 pt-2">
        <button
          type="button"
          @click="calcPriceMi"
          class="btn btn-secondary mt-4"
        >
          CALC
        </button>
      </div>
      <div v-if="price && 3 > 4" class="col-sm-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4 mb-3">
                <label for="load" class="form-label"
                  >#LOAD <b><span class="text-danger">*</span></b></label
                >
                <input
                  v-model="load"
                  type="text"
                  class="form-control"
                  id="load"
                  placeholder="466515194"
                  @change="print_tt"
                  @keyup="print_tt"
                />
              </div>
              <div class="col-sm-8 mb-3">
                <label for="note" class="form-label"
                  >Driver <b><span class="text-danger">*</span></b></label
                >
                <select
                  v-if="var_drivers.length"
                  v-model="note"
                  id="note"
                  class="form-select"
                  aria-label="Default select example"
                  @change="print_tt"
                >
                  <option
                    v-for="item in var_drivers"
                    :key="item.id"
                    :value="item.name"
                  >
                    {{
                      item.driver.name +
                      " : " +
                      item.driver.phone +
                      "  Truck: " +
                      item.tagname +
                      "  Trailer: " +
                      item.trailer.tagname
                    }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4 mb-3">
                <label for="eta" class="form-label"
                  >ETA <b><span class="text-danger">*</span></b></label
                >
                <select
                  v-model="eta"
                  id="eta"
                  class="form-select"
                  aria-label="eta"
                  @change="print_tt"
                >
                  <option
                    v-for="(item, index) in arrtimes"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4 mb-3">
                <label for="dateinit" class="form-label">PU-Time</label>
                <input
                  v-model="dateinit"
                  type="datetime-local"
                  class="form-control"
                  id="dateinit"
                  placeholder="2024-02-15 10:50:00"
                />
              </div>
              <div class="col-sm-4 mb-3">
                <label for="dateend" class="form-label">DEL-Time</label>
                <input
                  v-model="dateend"
                  type="datetime-local"
                  class="form-control"
                  id="dateend"
                  placeholder="2024-02-15 10:50:00"
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="rute" class="form-label">Rute</label>
                <input
                  v-model="rute"
                  type="text"
                  class="form-control"
                  id="rute"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="travel" class="form-label">TIME TRAVEL</label>
                <input
                  v-model="travel"
                  type="text"
                  class="form-control"
                  id="travel"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="milles" class="form-label">Milles</label>
                <input
                  v-model="milles"
                  type="number"
                  class="form-control"
                  id="milles"
                  disabled
                />
              </div>
              <div class="col-sm-3 mb-3">
                <label for="total" class="form-label">$ x mi</label>
                <input
                  v-model="total"
                  type="text"
                  class="form-control"
                  id="total"
                  disabled
                />
              </div>
              <div class="col-sm-12">
                <p v-html="traveltext"></p>
                <p v-html="travelgoal"></p>
              </div>
              <div class="col-sm-12 mb-3">
                <div class="d-grid gap-2">
                  <button
                    @click="saveTravel"
                    class="btn btn-success btn-sm"
                    type="button"
                  >
                    + ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="price && 3 > 4" class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 mb-3">
                <label for="dreamxmi" class="form-label">RPM</label>
                <input
                  v-model="dreamxmi"
                  type="text"
                  class="form-control"
                  id="dreamxmi"
                />
              </div>
              <div class="col-sm-12 mb-3">
                <label for="dreamxprice" class="form-label">Price ~</label>
                <input
                  v-model="dreamxprice"
                  type="text"
                  class="form-control"
                  id="dreamxprice"
                  disabled
                />
              </div>
              <div class="col-sm-12 mb-3">
                <div class="d-grid gap-2">
                  <button
                    @click="calcDmprice"
                    class="btn btn-primary btn-sm"
                    type="button"
                  >
                    CALC
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2 mt-4">
          <button
            :disabled="!searchweek"
            @click="calcDmprice"
            class="btn btn-info btn-sm"
            type="button"
          >
            WEEK ALL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  simpleGet,
  simplePost,
  DepartTime,
  drivers_notes,
  loadStatus,
} from "../api/constants";
import { Modal } from "bootstrap";
import { v4 as uuidv4 } from "uuid";
//import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    const price = ref();
    const var_noload = ref();
    const var_drivers = ref([]);
    const var_lrpm = ref();
    const var_miles_empty = ref();
    const var_mi_empty = ref();
    const var_timemi_empty = ref();
    const var_pick_up_location = ref();
    const var_pick_up_list = ref([]);
    const var_receiver = ref();
    const var_receiver_list = ref([]);
    const var_all_list = ref([]);
    const var_mi_list = ref([]);
    const is_calc = ref(false);
    //old
    const filter_view = ref("dashboard");
    const deleteindex_load = ref();
    const selected_load = ref();
    const searchload = ref();
    const search_note = ref("all");
    const searchweeki = ref();
    const searchweek = ref();
    const load_drivers = ref(false);
    const drivers = ref([]);
    const rute = ref(null);
    const trip = ref(null);
    const load = ref(null);
    const driver = ref(null);
    const eta = ref(null);
    const note = ref(null);
    const mempty = ref(null);
    const milles = ref(null);
    const travel = ref(null);
    const total = ref(0);
    const traveltext = ref("");
    const travelgoal = ref("");
    const dreamxmi = ref();
    const dreamxprice = ref();
    const xlist = ref([]);
    const xlistfilter = ref([]);
    const xlistfilterAll = ref([]);
    const xscoreweek = ref([]);
    const objscorewk = ref();
    const dateinit = ref(new Date());
    const dateend = ref(new Date());
    const arrtimes = ref(DepartTime);
    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    let modalEle = ref(null);
    let thisModalObj = null;
    let modalEleTrash = ref(null);
    let modalEleWeekDriver = ref(null);
    let thisModalObjTrash = null;
    let thisModalObjWeekDriver = null;
    const o_loadedit = ref();

    onMounted(async () => {
      const xdata_ft = await getTrucksDrivers();
      console.log("DATA TRUCKS", xdata_ft);
      var_drivers.value = xdata_ft;
      //
      const local_pick_up = localStorage.getItem("local_pick_up_list_fsc");
      var_pick_up_list.value = local_pick_up ? JSON.parse(local_pick_up) : [];
      //
      const local_receiver = localStorage.getItem("local_receiver_list_fsc");
      var_receiver_list.value = local_receiver
        ? JSON.parse(local_receiver)
        : [];
      format_miles_calc();
    });

    const getTrucksDrivers = async () => {
      const list_xdata = await simpleGet("truckscyinit");
      return list_xdata.data;
    };
    const add_pick_up = () => {
      if (var_pick_up_location.value) {
        const aux_list = [...var_pick_up_list.value];
        aux_list.push(var_pick_up_location.value);
        var_pick_up_list.value = aux_list;
        var_pick_up_location.value = "";
        localStorage.setItem(
          "local_pick_up_list_fsc",
          JSON.stringify(var_pick_up_list.value)
        );
        format_miles_calc();
      }
    };
    const delete_pick_up = (iter) => {
      if (var_pick_up_list.value.length) {
        const arr = [];
        var_pick_up_list.value.forEach((item, index) => {
          if (index != iter) {
            arr.push(item);
          }
        });
        var_pick_up_list.value = arr;
        format_miles_calc();
      }
    };
    const add_receiver = () => {
      if (var_receiver.value) {
        const aux_list = [...var_receiver_list.value];
        aux_list.push(var_receiver.value);
        var_receiver_list.value = aux_list;
        var_receiver.value = "";
        localStorage.setItem(
          "local_receiver_list_fsc",
          JSON.stringify(var_receiver_list.value)
        );
        format_miles_calc();
      }
    };
    const delete_receiver = (iter) => {
      if (var_receiver_list.value.length) {
        const arr = [];
        var_receiver_list.value.forEach((item, index) => {
          if (index != iter) {
            arr.push(item);
          }
        });
        var_receiver_list.value = arr;
        format_miles_calc();
      }
    };
    const format_miles_calc = () => {
      const arr = [];
      if (var_pick_up_list.value.length) {
        var_pick_up_list.value.forEach((item, index) => {
          arr.push({ type: "pick_up", element: item, iter: index + 1 });
        });
      }
      if (var_receiver_list.value.length) {
        var_receiver_list.value.forEach((item, index) => {
          arr.push({ type: "receiver", element: item, iter: index + 1 });
        });
      }
      var_all_list.value = arr;
      info_broker();
    };
    const info_broker = () => {
      if (var_all_list.value.length > 1) {
        let tp = "";
        const arr = var_all_list.value;
        for (let i = 0; i < arr.length; i++) {
          const auxl = arr[i].element.split(", ");
          const xtp = auxl.length > 1 ? auxl[1].substring(0, 2) : "xx";
          tp += i == 0 ? xtp : "-" + xtp;
        }
        rute.value = tp;
      }
      traveltext.value =
        rute.value +
        " <b>" +
        var_noload.value +
        "</b>: " +
        note.value +
        "  <b>ETA " +
        eta.value +
        "</b>";
    };
    const reset_var_form = () => {
      //here
      price.value = 0;
      var_noload.value = "";
      var_miles_empty.value = "";
      var_timemi_empty.value = "";
      var_mi_empty.value = 0;
      var_lrpm.value = 0;
      var_pick_up_location.value = "";
      var_pick_up_list.value = [];
      var_receiver.value = "";
      var_receiver_list.value = [];
      var_all_list.value = [];
      var_mi_list.value = [];
      rute.value = "";
      note.value = "";
      eta.value = "";
      milles.value = 0;
      is_calc.value = false;
      localStorage.setItem(
        "local_pick_up_list_fsc",
        JSON.stringify(var_pick_up_list.value)
      );
      localStorage.setItem(
        "local_receiver_list_fsc",
        JSON.stringify(var_receiver_list.value)
      );
    };
    const calcform_var_form = async () => {
      //here
      if (var_all_list.value.length > 1) {
        let mi_init = 0;
        const arr = var_all_list.value;
        //1 calc mi empty
        //var_miles_empty.value
        if (var_miles_empty.value) {
          const dx = await calcDist(var_miles_empty.value, arr[0].element); //status
          const mi_x =
            dx.data.response.rows[0].elements[0].distance.value *
            0.000621371192;
          mi_init = Math.round((mi_x + Number.EPSILON) * 100) / 100;
          if (mi_init > 1) {
            mi_init = Math.round(mi_x);
          }
          var_mi_empty.value = mi_init;
          var_timemi_empty.value = toHM(
            dx.data.response.rows[0].elements[0].duration.value
          );
          console.log("DIST EMP:", dx);
        }
        //2 calc trips
        milles.value = 0;
        let tmiles = 0;
        let ttimes = 0;
        const tripaux = [];
        for (let i = 0; i < arr.length - 1; i++) {
          const d = await calcDist(arr[i].element, arr[i + 1].element); //status
          const mi_travel = Math.round(
            d.data.response.rows[0].elements[0].distance.value * 0.000621371192
          );
          tripaux.push({
            origin: arr[i].element,
            destination: arr[i + 1].element,
            mi: mi_travel,
            time: toHM(d.data.response.rows[0].elements[0].duration.value),
            distance: d.data.response.rows[0].elements[0].distance,
            duration: d.data.response.rows[0].elements[0].duration,
            status: d.data.response.rows[0].elements[0].status,
          });
          tmiles += d.data.response.rows[0].elements[0].distance.value;
          ttimes += d.data.response.rows[0].elements[0].duration.value;
        }
        var_mi_list.value = tripaux;
        milles.value = Math.round(tmiles * 0.000621371192);
        travel.value = toHM(ttimes);
        const vmi = price.value / milles.value;
        const vmi_with_empty = price.value / (milles.value + mi_init);
        total.value = Math.round((vmi_with_empty + Number.EPSILON) * 100) / 100;
        var_lrpm.value = Math.round((vmi + Number.EPSILON) * 100) / 100;
        //console.log("DIST:", tripaux);
        //textTravelOut();
        is_calc.value = true;
      }
    };

    //old

    const getXLoads = async () => {
      const list_xloads = await simpleGet("xloads/simple");
      return list_xloads.data;
    };
    const createXLoads = async (item) => {
      const obj = await simplePost("xloads", item);
      return obj;
    };
    const updateXLoads = async (item) => {
      const obj = await simplePost("xloads/update/" + item.uuid, item);
      return obj.data;
    };
    const changeWeekEnd = () => {
      localStorage.setItem("dweekendfl_fsc", JSON.stringify(searchweek.value));
      filterListLoads();
    };
    const loadDvrAll = async () => {
      //here
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        RequestDTO: {
          PartnerId: 1,
          HOSClientId: 27652,
          ExternalClientId: 23627,
          HOSHomeBaseIds: [29281],
        },
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        "https://livetrack.atcompass.net/ws/WSHOS.asmx/GetHOSDrivers",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const o_all = result ? JSON.parse(result) : [];
          drivers.value = o_all.d;
          drivers.value = drivers.value.filter((item) => item.IsActive == 1);
          localStorage.setItem("xdrivers_fsc", JSON.stringify(drivers.value));
        })
        .catch((error) => console.error(error));
    };
    const calcPriceMi = () => {
      if (!trip.value || !price.value) {
        //here
        alert("Debe completar los campos");
      } else {
        calcTrips();
      }
    };
    const saveTravel = async () => {
      if (!trip.value || !price.value) {
        //here
        alert("Debe completar los campos");
      } else {
        const init = getDayWeek(dateinit.value);
        const end = getDayWeek(dateend.value);
        const weeki = getNumWeek(dateinit.value);
        const week = getNumWeek(dateend.value);
        const newItem = {
          //uuid: uuidv4(),
          rute: rute.value,
          trip: trip.value,
          load: load.value,
          driver: driver.value,
          eta: eta.value,
          note: note.value,
          milles: milles.value,
          price: price.value,
          travel: travel.value,
          traveltext: traveltext.value,
          travelgoal: travelgoal.value,
          total: total.value,
          dateinit: dateinit.value,
          dateend: dateend.value,
          weeki: weeki,
          week: week,
          init: init,
          end: end,
          status: "Pending",
        };
        await createXLoads(newItem);
        xlist.value = await getXLoads();
        filterListLoads();
        resetForm();
      }
    };
    const resetForm = () => {
      rute.value = null;
      trip.value = null;
      load.value = null;
      note.value = null;
      milles.value = null;
      price.value = null;
      total.value = 0;
      travel.value = null;
      traveltext.value = null;
      travelgoal.value = null;
      dateinit.value = new Date();
      dateend.value = null;
    };
    const calcTrips = async () => {
      if (trip.value) {
        milles.value = 0;
        let tmiles = 0;
        let ttimes = 0;
        const tripaux = [];
        const arr = trip.value.split(";");
        if (arr.length > 1) {
          for (let i = 0; i < arr.length - 1; i++) {
            const d = await calcDist(arr[i], arr[i + 1]); //status
            tripaux.push({
              origin: arr[i],
              destination: arr[i + 1],
              distance: d.data.response.rows[0].elements[0].distance,
              duration: d.data.response.rows[0].elements[0].duration,
              status: d.data.response.rows[0].elements[0].status,
            });
            tmiles += d.data.response.rows[0].elements[0].distance.value;
            ttimes += d.data.response.rows[0].elements[0].duration.value;
          }
        }
        milles.value = Math.round(tmiles * 0.000621371192);
        travel.value = toHM(ttimes);
        const vmi = price.value / milles.value;
        total.value = Math.round((vmi + Number.EPSILON) * 100) / 100;
        //console.log("DIST:", tripaux);
        textTravelOut();
      }
    };
    const calcDist = async (a, b) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          origins: a,
          destinations: b,
        }),
        redirect: "follow",
      };
      const info = await fetch(
        "https://spartan.app.3dboosterstudio.com/api/maps",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const o_all = result ? JSON.parse(result) : [];
          return o_all;
        })
        .catch((error) => console.error(error));
      return info;
    };
    const trashDates = () => {
      xlist.value = [];
      localStorage.setItem("dhlist_fsc", JSON.stringify(xlist.value));
      filterListLoads();
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    const xround = (value) => {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    };
    const formatDate = (v) => {
      const d = new Date(v);
      return (
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear().padLeft(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getUTCMinutes().padLeft(),
          d.getUTCSeconds().padLeft(),
        ].join(":")
      );
    };
    const toHM = (totalSeconds) => {
      const totalMinutes = Math.floor(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      let dttime = "";
      if (hours) {
        dttime += hours + "h";
      }
      if (minutes) {
        if (dttime) {
          dttime += " ";
        }
        dttime += minutes + "m";
      }
      return dttime;
    };
    const textTravelOut = () => {
      print_tt();
    };
    const calcDmprice = () => {
      if (dreamxmi.value && milles.value) {
        dreamxprice.value = Math.round(dreamxmi.value * milles.value);
      }
    };
    const getDayWeek = (d) => {
      const date = new Date(d);
      const currentDayOfWeek = daysOfWeek[date.getDay()];
      return currentDayOfWeek;
    };
    const getNumWeek = (dx) => {
      const dxx = new Date(dx);
      let d = new Date(
        Date.UTC(dxx.getFullYear(), dxx.getMonth(), dxx.getDate())
      );
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return weekNo;
    };
    const print_tt = () => {
      let tp_goal = "";
      if (trip.value) {
        const arr = trip.value.split(";");
        if (arr.length > 1) {
          let tp = "";
          for (let i = 0; i < arr.length; i++) {
            const auxl = arr[i].split(", ");
            const xtp = auxl.length > 1 ? auxl[1].substring(0, 2) : "xx";
            tp += i == 0 ? xtp : "-" + xtp;
            tp_goal += i == 0 ? arr[i] : " =" + i + "X=> " + arr[i];
          }
          console.log(tp);
          rute.value = tp;
        }
      }
      traveltext.value =
        rute.value +
        " <b>" +
        load.value +
        "</b>: " +
        note.value +
        "  <b>ETA " +
        eta.value +
        "</b>";
      //(We_06 ) $1300 (813 mi, with mi empty 1.60 x mi) 12h:45m travel
      //
      travelgoal.value =
        "(" +
        tp_goal +
        ") <b>$" +
        price.value +
        "</b> (<b>" +
        milles.value +
        "</b> mi, with mi empty <b>" +
        total.value +
        "</b> x mi) " +
        travel.value +
        " travel";
    };
    const viewLoad = (index) => {
      selected_load.value = xlistfilter.value[index];
      o_loadedit.value = selected_load.value.uuid;
      thisModalObj.show();
    };
    const save_editload = async () => {
      /*xlist.value.forEach(async (item) => {
        if (item.uuid == o_loadedit.value) {
          //here
        }
      });*/
      selected_load.value.init = getDayWeek(selected_load.value.dateinit);
      selected_load.value.end = getDayWeek(selected_load.value.dateend);
      selected_load.value.weeki = getNumWeek(selected_load.value.dateinit);
      selected_load.value.week = getNumWeek(selected_load.value.dateend);
      await datanotetravel();
    };
    const datanotetravel = async () => {
      let tp_goal = "";
      if (selected_load.value.trip) {
        const arr = selected_load.value.trip.split(";");
        if (arr.length > 1) {
          let tp = "";
          for (let i = 0; i < arr.length; i++) {
            const auxl = arr[i].split(", ");
            const xtp = auxl.length > 1 ? auxl[1].substring(0, 2) : "xx";
            tp += i == 0 ? xtp : "-" + xtp;
            tp_goal += i == 0 ? arr[i] : " =" + i + "X=> " + arr[i];
          }
          console.log(tp);
          selected_load.value.rute = tp;
        }
      }
      selected_load.value.traveltext =
        selected_load.value.rute +
        " <b>" +
        selected_load.value.load +
        "</b>: " +
        selected_load.value.note +
        "  <b>ETA " +
        selected_load.value.eta +
        "</b>";
      selected_load.value.travelgoal =
        "(" +
        tp_goal +
        ") <b>$" +
        selected_load.value.price +
        "</b> (<b>" +
        selected_load.value.milles +
        "</b> mi, with mi empty <b>" +
        selected_load.value.total +
        "</b> x mi) " +
        selected_load.value.travel +
        " travel";
      await updateXLoads(selected_load.value);
      xlist.value = await getXLoads();
      filterListLoads();
    };
    const trashItemDate = (index) => {
      console.log(selected_load.value);
      deleteindex_load.value = xlistfilter.value[index].uuid;
      selected_load.value = xlistfilter.value[index];
      thisModalObjTrash.show();
    };
    const deleteItemDate = async () => {
      const itemDel = selected_load.value;
      itemDel.status = "Deleted";
      await updateXLoads(itemDel);
      xlist.value = await getXLoads();
      /*xlist.value = xlist.value.filter(
        (item, iter) => item.uuid != selected_load.value.uuid
      );*/
      filterListLoads();
      thisModalObjTrash.hide();
    };
    const weekDriverItem = () => {
      //calc utilidades del filtro
      let total_mi = 0;
      let total_price = 0;
      let total_rpm = 0;
      xscoreweek.value = [];
      if (xlistfilterAll.value.length) {
        xlistfilterAll.value.forEach((item) => {
          const vmi = item.price / item.milles;
          const rpm = Math.round((vmi + Number.EPSILON) * 100) / 100;
          total_mi += item.milles;
          total_price += item.price;
          total_rpm += rpm;
          xscoreweek.value.push({
            mi: item.milles,
            v: item.price,
            rpm: item.total,
          });
        });
        //const avgrpm = total_rpm / xlistfilterAll.value.length;
        const xrpm = total_price / total_mi;
        total_rpm = Math.round((xrpm + Number.EPSILON) * 100) / 100;
        objscorewk.value = { mi: total_mi, p: total_price, rpm: total_rpm };
      }
      thisModalObjWeekDriver.show();
    };
    const getColorStatus = (status) => {
      //
      if (status == "Pending") {
        return "secondary";
      } else if (status == "Completed") {
        return "success";
      } else if (status == "In progress") {
        return "primary";
      } else if (status == "Cancelled") {
        return "danger";
      }
      return "warning";
    };
    const getLastTrip = (trip) => {
      const t = trip.split(";");
      return t[t.length - 1];
    };
    const getRuteX = (rute) => {
      const r = rute.split("-");
      return r.length > 2 ? rute.substring(3, rute.length) : rute;
    };
    const filterListLoads = () => {
      if (
        searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value != "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.load.includes(searchload.value) &&
            item.weeki == searchweeki.value &&
            item.note == search_note.value &&
            item.week == searchweek.value
        );
      } else if (
        searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value == "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.load.includes(searchload.value) &&
            item.weeki == searchweeki.value &&
            item.week == searchweek.value
        );
      } else if (
        !searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value != "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value &&
            item.note == search_note.value &&
            item.week == searchweek.value
        );
      } else if (
        !searchload.value &&
        searchweeki.value &&
        searchweek.value &&
        search_note.value == "all"
      ) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value && item.week == searchweek.value
        );
      } else if (searchload.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter((item, iter) => {
          return (
            item.load.includes(searchload.value) &&
            item.note == search_note.value
          );
        });
      } else if (searchload.value) {
        xlistfilter.value = xlist.value.filter((item, iter) => {
          return item.load.includes(searchload.value);
        });
      } else if (searchweeki.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.weeki == searchweeki.value && item.note == search_note.value
        );
      } else if (searchweeki.value) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.weeki == searchweeki.value
        );
      } else if (searchweek.value && search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) =>
            item.week == searchweek.value && item.note == search_note.value
        );
      } else if (searchweek.value) {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.week == searchweek.value
        );
      } else if (search_note.value != "all") {
        xlistfilter.value = xlist.value.filter(
          (item, iter) => item.note == search_note.value
        );
      } else {
        xlistfilter.value = xlist.value;
      }
      xlistfilter.value.sort(function (a, b) {
        const dateA = new Date(a.dateend);
        const dateB = new Date(b.dateend);
        return dateA - dateB;
      });
      const arr_filtered = xlistfilter.value.sort(function (a, b) {
        const dateA = new Date(a.dateend);
        const dateB = new Date(b.dateend);
        return dateA - dateB;
      });
      xlistfilterAll.value = arr_filtered;
      xlistfilter.value = arr_filtered;
      if (filter_view.value == "dashboard") {
        const arr_inverse = arr_filtered.reverse();
        const includ = [];
        const filtedout = [];
        arr_inverse.forEach((element) => {
          if (!includ.includes(element.note)) {
            //includ
            includ.push(element.note);
            filtedout.push(element);
          }
        });
        xlistfilter.value = filtedout.reverse();
      }
      return xlistfilter.value;
    };
    return {
      var_drivers,
      var_miles_empty,
      var_timemi_empty,
      var_lrpm,
      var_mi_empty,
      var_pick_up_location,
      var_pick_up_list,
      add_pick_up,
      delete_pick_up,
      add_receiver,
      delete_receiver,
      var_receiver,
      var_receiver_list,
      var_all_list,
      var_mi_list,
      var_noload,
      info_broker,
      reset_var_form,
      calcform_var_form,
      is_calc,
      calcDmprice,
      calcPriceMi,
      saveTravel,
      trashItemDate,
      deleteItemDate,
      trashDates,
      formatNumber,
      xround,
      formatDate,
      dreamxprice,
      dreamxmi,
      rute,
      trip,
      load,
      driver,
      drivers,
      eta,
      note,
      mempty,
      milles,
      price,
      travel,
      total,
      xlist,
      xlistfilter,
      toHM,
      traveltext,
      travelgoal,
      print_tt,
      arrtimes,
      dateinit,
      dateend,
      getDayWeek,
      getNumWeek,
      searchload,
      filterListLoads,
      modalEle,
      modalEleTrash,
      modalEleWeekDriver,
      selected_load,
      viewLoad,
      searchweek,
      search_note,
      searchweeki,
      changeWeekEnd,
      drivers_notes,
      save_editload,
      weekDriverItem,
      loadStatus,
      getColorStatus,
      filter_view,
      getLastTrip,
      getRuteX,
      xscoreweek,
      objscorewk,
    };
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.timeline-1 {
  border-left: 3px solid #22a6c3;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(177, 99, 163, 0.09);
  margin: 0 auto;
  margin-left: 30%;
  position: relative;
  padding: 30px;
  list-style: none;
  text-align: left;
  max-width: 60%;
}

@media (max-width: 767px) {
  .timeline-1 {
    max-width: 98%;
    padding: 20px;
  }
}

.timeline-1 .event {
  border-bottom: 1px dashed #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

.timeline-1 .event p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .timeline-1 .event {
    padding-top: 30px;
  }
}

.timeline-1 .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-1 .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline-1 .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline-1 .event:after {
  -webkit-box-shadow: 0 0 0 3px #125c6b;
  box-shadow: 0 0 0 3px #125c6b;
  left: -35.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 55px;
}

@media (max-width: 767px) {
  .timeline-1 .event:after {
    left: -31.8px;
  }
}
</style>
